export enum QueryKeys {
  TOKENS = 'TOKENS',
  TOKENS_LIST = 'TOKENS_LIST',
  TOKEN_DETAILS = 'TOKEN_DETAILS',
  POPULAR_TOKENS = 'POPULAR_TOKENS',
  PAIRS = 'PAIRS',
  PAIR_DETAILS = 'PAIR_DETAILS',
  TOKENS_IDS = 'TOKENS_IDS',
  TOKENS_PRICES = 'TOKENS_PRICES',
  FACTORY_DATA = 'FACTORY_DATA',
  PAIR_DAY_DATA = 'PAIR_DAY_DATA',
  TOKEN_PRICE = 'TOKEN_PRICE',
  PAIR_HISTORICAL_DATA = 'PAIR_HISTORICAL_DATA',
  PAIR_HISTORICAL_DATA_LAST_DAY = 'PAIR_HISTORICAL_DATA_LAST_DAY',
  TOKEN_BALANCE = 'TOKEN_BALANCE',
  USER_POSITIONS = 'USER_POSITIONS',
  VERIFIED_USER_POSITIONS = 'VERIFIED_USER_POSITIONS',
  SP_NFTS_POSITIONS = 'SP_NFTS_POSITIONS',
  PAIR_USER_POSITIONS = 'PAIR_USER_POSITIONS',
  STAKING_POSITION = 'STAKING_POSITION',
  POOL_TOKEN_IDS = 'POOL_TOKEN_IDS',
  LEADERBOARD_LIST = 'LEADERBOARD_LIST',
  LEADERBOARD_TOTAL_POINTS = 'LEADERBOARD_TOTAL_POINTS',
  LEADERBOARD_ACCOUNT_POINTS = 'LEADERBOARD_ACCOUNT_POINTS',
  ALL_PAIRS = 'ALL_PAIRS',
  PARTNERS_LIST = 'PARTNERS_LIST',
  PARTNER_DETAILS = 'PARTNER_DETAILS',
  PARTNER_HIGHLIGHTS = 'PARTNER_HIGHLIGHTS',
  TRANSACTIONS = 'TRANSACTIONS',
  TRANSACTIONS_V4 = 'TRANSACTIONS_V4',
  LEADERBOARD_NEXT_UPDATE = 'LEADERBOARD_NEXT_UPDATE',
  GEO = 'GEO',
  TOKENS_RATE = 'TOKENS_RATE',
  POOLS = 'POOLS',
  MULTIPLE_POOLS = 'MULTIPLE_POOLS',
  NATIVE_PRICE = 'NATIVE_PRICE',
  SINGLE_TOKEN = 'SINGLE_TOKEN',
  POOLS_APR = 'POOLS_APR',
  POOLS_TVL = 'POOLS_TVL',
  SINGLE_POOL = 'SINGLE_POOL',
  POOL_FEE_DATA = 'POOL_FEE_DATA',
  MERKL_USER_REWARDS = 'MERKL_USER_REWARDS',
  MERKL_CAMPAIGNS = 'MERKL_CAMPAIGNS',
  TOKENS_PRICES_FROM_GRAPH = 'TOKENS_PRICES_FROM_GRAPH',
  LEADERBOARD_ACCOUNT_TICKETS = 'LEADERBOARD_ACCOUNT_TICKETS',
  USER_WALLET = 'USER_WALLET',
  REFERRALS_LIST = 'REFERRALS_LIST',
  TOP_REFERRALS = 'TOP_REFERRALS',
  ELIGIBLE_TO_REFERRAL = 'ELIGIBLE_TO_REFERRAL',
  AIRDROP_CLAIM = 'AIRDROP_CLAIM',
  AIRDROP_TOKEN_DETAILS = 'AIRDROP_TOKEN_DETAILS',
  USER_HAS_LIQUIDITY = 'USER_HAS_LIQUIDITY',
  MULTIPLE_TOKENS = 'MULTIPLE_TOKENS',
  AMM_RECENT_DAILY_STATS = 'AMM_RECENT_DAILY_STATS',
  SMART_POOLS = 'SMART_POOLS',
  STRATEGY_DATA = 'STRATEGY_DATA',
  VAULT_APR = 'VAULT_APR',
  VAULT_BALANCES = 'VAULT_BALANCES',
  STEER_VAULTS = 'STEER_VAULTS',
  STEER_VAULTS_POSITIONS = 'STEER_VAULTS_POSITIONS',
  IS_ACTIVE_POOL = 'IS_ACTIVE_POOL',
  FETCH_NFT_TOKENS = 'FETCH_NFT_TOKENS',
  BOOST_DETAILS = 'BOOST_DETAILS',
  BOOSTER_STATS = 'BOOSTER_STATS',
  KP_NFT_PENDING_REWARDS = 'KP_NFT_PENDING_REWARDS',
  VAULTS_ACTIVE_POOLS = 'VAULTS_ACTIVE_POOLS',
  KIM_INFO = 'KIM_INFO',
  NFT_POOL_ADDRESS = 'NFT_POOL_ADDRESS',
  VAULT_POSITION_FEES = 'VAULT_POSITION_FEES',
  NITRO_POOLS_LIST = 'NITRO_POOLS_LIST',
  NITRO_POOL_USER_TOKEN_ID = 'NITRO_POOL_USER_TOKEN_ID',
  NFT_POOL = 'NFT_POOL',
  NITRO_POSITIONS_LIST = 'NITRO_POSITIONS_LIST',
  NITRO_POOL_BY_ID = -'NITRO_POOL_BY_ID',
  NFT_POOL_ADDRESSES = 'NFT_POOL_ADDRESSES',
  NITRO_POOLS_BY_NFT_POOL_ADDRESSES = 'NITRO_POOLS_BY_NFT_POOL_ADDRESSES',
  GET_PROTOCOL_QUOTES = 'GET_PROTOCOL_QUOTES',
  LOTTERY_ACCOUNT_TICKETS = 'LOTTERY_ACCOUNT_TICKETS',
  ROUTER_ACCOUNT_TICKETS = 'ROUTER_ACCOUNT_TICKETS',
  SWAP_BEST_CALL = 'SWAP_BEST_CALL',
  POSITION_FEES = 'POSITION_FEES',
  VESTING_DETAILS = 'VESTING_DETAILS',
  DIVIDENDS_USAGE_ALLOCATION = 'DIVIDENDS_USAGE_ALLOCATION',
  IS_ELIGIBLE_TO_CLAIM_BADGE = 'IS_ELIGIBLE_TO_CLAIM_BADGE',
  HAS_CLAIMED = 'HAS_CLAIMED',
  GET_GAMMA_VAULTS = 'GET_GAMMA_VAULTS',
  GET_GAMMA_POSITIONS = 'GET_GAMMA_POSITIONS',
  LAUNCHPAD_DETAILS = 'LAUNCHPAD_DETAILS',
  LAUNCHPAD_LIST = 'LAUNCHPAD_LIST',
  GET_EXPRESSED_INTREST_COUNT = 'GET_EXPRESSED_INTREST_COUNT',
  GET_SYNAPSE_SESSION_ID = 'GET_SYNAPSE_SESSION_ID',
  GET_EXPRESSED_INTREST = 'GET_EXPRESSED_INTREST',
  SYNAPSE_STATUS = 'SYNAPSE_STATUS',
  GET_LAUNCHPAD_USER = 'GET_LAUNCHPAD_USER'
}
