import { HStack, Text } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

interface Props {
  label: string | ReactNode;
  value: string | ReactNode;
}

export const InfoRow: FC<Props> = ({ label, value }) => {
  return (
    <HStack
      w="100%"
      gap={4}
      justifyContent="space-between"
      sx={{
        '&:not(:first-of-type)': { margin: '6px 0' },
        '&:not(:last-of-type)': { margin: '6px 0' }
      }}
      width="100%"
    >
      {typeof label === 'string' ? (
        <Text
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'whiteOpacity.50',
            fontFamily: 'p'
          }}
        >
          {label}
        </Text>
      ) : (
        label
      )}
      {typeof value === 'string' ? (
        <Text
          sx={{
            fontSize: '12px',
            fontWeight: 400,
            color: 'whiteOpacity.50',
            fontFamily: 'p'
          }}
        >
          {value}
        </Text>
      ) : (
        value
      )}
    </HStack>
  );
};
