import React, { ReactNode } from 'react';

import { getConfig } from '@app/config';
import { APP_ROUTES } from '@app/constants/routes';
// import X from '@app/assets/images/social_media/twitter.svg';
// import Discord from '@app/assets/images/social_media/discord.svg';
// import Youtube from '@app/assets/images/social_media/youtube.svg';
// import Instagram from '@app/assets/images/social_media/instagram.svg';
// import Facebook from '@app/assets/images/social_media/facebook.svg';
// import Vector from '@app/assets/images/social_media/vector.svg';
import X from '@app/assets/images/social_media/twitter.svg';
import Discord from '@app/assets/images/social_media/discord.svg';
import Warpcast from '@app/assets/images/social_media/warpcast.svg';
import Telegram from '@app/assets/images/social_media/telegram.svg';
import Mirror from '@app/assets/images/social_media/mirror.svg';

export const footerNav: (chainId: number | undefined) => {
  title: string;
  options: {
    label: string | ReactNode;
    link: string;
    isExternal?: boolean;
  }[];
}[] = chainId => [
  {
    title: 'Resources',
    options: [
      {
        label: 'Audit',
        link: '',
        isExternal: true
      },
      {
        label: 'Contracts',
        link: '',
        isExternal: true
      },
      {
        label: 'Documentation',
        link: 'https://docs.scribe.exchange/',
        isExternal: true
      },
      {
        label: 'Leaderboard',
        link: getConfig(chainId)?.FLAGS.showLeaderboardPage
          ? APP_ROUTES.leaderboard
          : ''
      }
    ]
  },
  {
    title: 'Tools',
    options: [
      {
        label: 'Scroll Bridge',
        link: 'https://scroll.io/bridge',
        isExternal: true
      },
      {
        label: 'Router Nitro',
        link: 'https://app.routernitro.com/',
        isExternal: true
      },
      {
        label: 'Symbiosis Bridge',
        link: 'https://app.symbiosis.finance/swap',
        isExternal: true
      },
      {
        label: 'Retro Bridge',
        link: 'https://app.retrobridge.io/?utm_source=scribe',
        isExternal: true
      },

      {
        label: 'DefiLlama',
        link: 'https://defillama.com/protocol/scribe-v4#information',
        isExternal: true
      }
    ]
  }
];

export const socials = [
  {
    label: 'X',
    link: 'https://x.com/ScribeDEX',
    isExternal: true,
    img: <X />
  },
  {
    label: 'Discord',
    link: 'https://discord.gg/scribeprotocol',
    isExternal: true,
    img: <Discord />
  },

  {
    label: 'Warpcast',
    link: 'https://warpcast.com/scribedex',
    isExternal: true,
    img: <Warpcast />
  },

  {
    label: 'Telegram',
    link: 'https://t.me/scribedex',
    isExternal: true,
    img: <Telegram />
  },
  {
    label: 'Mirror',
    link: 'https://mirror.xyz/scribedex.eth',
    isExternal: true,
    img: <Mirror />
  }
];
// export const socials = [
//   {
//     label: 'X',
//     link: 'https://x.com/ScribeDEX',
//     isExternal: true,
//     img: <X />
//   },
//   {
//     label: 'Discord',
//     link: 'https://discord.gg/scribeprotocol',
//     isExternal: true,
//     img: <Discord />
//   },
//   {
//     label: 'Youtube',
//     link: 'https://discord.gg/scribeprotocol]',
//     isExternal: true,
//     img: <Youtube />
//   },
//   {
//     label: 'Instagram',
//     link: 'https://discord.gg/scribeprotocol',
//     isExternal: true,
//     img: <Instagram />
//   },
//   {
//     label: 'Facebook',
//     link: 'https://discord.gg/scribeprotocol',
//     isExternal: true,
//     img: <Facebook />
//   },
//   {
//     label: 'vector',
//     link: 'https://discord.gg/scribedex',
//     isExternal: true,
//     img: <Vector />
//   }

//   // {
//   //   label: 'Telegram',
//   //   link: 'https://t.me/scribedex',
//   //   isExternal: true
//   // },
//   // {
//   //   label: 'Mirror',
//   //   link: 'https://mirror.xyz/scribedex.eth',
//   //   isExternal: true
//   // },
// ];
