import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Box, Button, HStack, Text } from '@chakra-ui/react';
import {
  getGasPrice,
  ThirdwebSDK,
  useAddress,
  useChain
} from '@thirdweb-dev/react';
import React, { useCallback } from 'react';
import { RPC_OVERRIDE_KEY } from '@app/constants/chains';
import { useRouter } from 'next/router';
import { useAsync } from 'react-use';
import { Scroll } from '@thirdweb-dev/chains';

const AVAILABLE_RPC_MAINNET = [
  {
    label: 'Official mainnet',
    value: ['https://rpc.scroll.io']
  },
  // {
  //   label: 'BlastAPI',
  //   value: [
  //     'https://scroll-mainnet.blastapi.io/4b61f88d-fbb6-464d-8117-d9b5e2f95fb3'
  //   ]
  // },
  // {
  //   label: '1rpc',
  //   value: ['https://1rpc.io/scroll']
  // },
  {
    label: 'Thirdweb',
    value: ['https://534352.rpc.thirdweb.com/3c7d89bce946b0f62021bd3a4220b7cf']
  }
];

function getActiveRpcLabel() {
  try {
    const override = localStorage.getItem(RPC_OVERRIDE_KEY);

    if (override) {
      const parsed = JSON.parse(override);

      return parsed?.label || 'Default';
    }

    return 'Default Rpc';
  } catch (e) {
    return 'Default Rpc';
  }
}

export const RpcSelector = () => {
  const router = useRouter();
  const address = useAddress();
  const activeRpcLabel = getActiveRpcLabel();
  const chain = useChain();

  const handleRpcSelect = useCallback(
    (value: { label: string; value: string[] }) => {
      try {
        localStorage.setItem(RPC_OVERRIDE_KEY, JSON.stringify(value));

        router.reload();
      } catch (e) {
        console.error(e);
      }
    },
    [router]
  );

  const { value, loading } = useAsync(async () => {
    if (!chain) {
      return;
    }

    let options: { label: string; value: string[] }[];

    switch (chain.chainId) {
      case Scroll.chainId: {
        options = AVAILABLE_RPC_MAINNET;
        break;
      }
      default: {
        options = [];
      }
    }

    return Promise.allSettled(
      options.map(async rpcOption => {
        const sdk = new ThirdwebSDK(chain?.name, {
          readonlySettings: {
            rpcUrl: rpcOption.value[0]
          }
        });
        const start = performance.now();

        await getGasPrice(sdk.getProvider());

        const end = performance.now();

        const latency = end - start;

        return { ...rpcOption, latency };
      })
    ).then(res => {
      return res
        .filter(item => item.status === 'fulfilled')
        .map(item => {
          // @ts-ignore
          return item.value;
        });
    });
  }, [chain]);

  const activeLatency = value?.find(item => item.label === activeRpcLabel)
    ?.latency;

  if (!address || loading) {
    return null;
  }

  return (
    <>
      <Menu placement="bottom-start">
        <MenuButton
          as={Button}
          sx={{
            padding: '8px 14px',
            background: 'gray.11',
            borderRadius: '8px',
            width: '100%'
          }}
        >
          <HStack width="100%">
            <Box
              sx={{
                width: '8px',
                minWidth: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundColor: 'success'
              }}
            />
            <Text
              color="white"
              fontSize={14}
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {activeRpcLabel}
            </Text>
            <Text color="white" fontSize={14} ml="auto">
              {activeLatency ? `${activeLatency.toFixed()} ms` : ''}
            </Text>
          </HStack>
        </MenuButton>
        <MenuList
          sx={{
            backgroundColor: 'gray.12',
            borderRadius: '8px',
            minWidth: '300px',
            overflow: 'hidden'
          }}
        >
          {value?.map(item => {
            return (
              <MenuItem
                key={item.label}
                minH="48px"
                p="12px"
                gap="12px"
                borderBottom="1px solid"
                borderColor="whiteOpacity.12"
                onClick={() => handleRpcSelect(item)}
              >
                <HStack width="100%">
                  <Box
                    sx={{
                      width: '8px',
                      height: '8px',
                      borderRadius: '50%',
                      backgroundColor: 'success'
                    }}
                  />
                  <Text color="white" fontSize={14}>
                    {item.label}
                  </Text>
                  <Text color="white" fontSize={14} ml="auto">
                    {item.latency.toFixed()} ms
                  </Text>
                </HStack>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};
