import { ModeEnvs } from '@app/constants/chains';
import { ApplicationConfig } from '@app/config/types';
import { Token } from '@app/types/token';

export const scrollMainnetConfig: ApplicationConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID ?? '',
  CONTENT: {
    tokensWhitelist: process.env.NEXT_PUBLIC_WHITELIST_TOKENS
      ? (JSON.parse(
          process.env.NEXT_PUBLIC_WHITELIST_TOKENS
        ) as unknown as Token[])
      : [],
    useWhiteListTokens: true,
    publicSaleLink: '',
    poolsOrder: [],
    hiddenPools: [],
    defaultTokens: {
      from: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      to: '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4'
    }
  },
  ALGEBRA_ADDRESSES: {
    POOL_INIT_CODE_HASH:
      '0x4b9e4a8044ce5695e06fce9421a63b6f5c3db8a561eebb30ea4c775469e36eaf',
    ALGEBRA_FACTORY: '0xdc62acdf75cc7ea4d93c69b2866d9642e79d5e2e',
    ALGEBRA_POOL_DEPLOYER: '0xbAE27269D777D6fc0AefFa9DfAbA8960291E51eB',
    ALGEBRA_POSITION_MANAGER: '0x8b370dc23bE270a7FA78aD3803fCaAe549Ac21fc',
    ALGEBRA_QUOTER: '0x255F1F3E635F3bc70af0e49C0dE571bF7E6BC4D2',
    ALGEBRA_QUOTER_V2: '0x922c15652f3A18FBE868B38BD1E3b21E3a91c7ad',
    ALGEBRA_ROUTER: '0xB9D4EB6518A437b9a012aa3dA50b5CAE2439bc9D'
  },
  MERKL: {
    DISTRIBUTOR: ''
  },
  AIRDROP_CONTRACTS: {},
  AIRDROP_TOKENS: {},
  STEER: {
    PROXY_HELPER: ''
  },
  GAMMA: {
    PROXY_HELPER: '0xa4C8ddB36E267C01Ad4d88b933d1Df92168adA85',
    UNI_PROXY: '0xBd3332466d13588B1BFe8673B58190645bFE26bE'
  },
  CONTRACTS: {
    SCRIBE_MASTER: '',
    FACTORY: '',
    ROUTER: '',
    WETH: '0x5300000000000000000000000000000000000004',
    SCRIBE: '',
    X_SCRIBE: '',
    DIVIDENDS: '',
    NFT_POOL_FACTORY: '',
    YIELD_BOOSTER: '',
    NITRO_POOL_FACTORY: ''
  },
  FLAGS: {
    showBadgesPage: true,
    showLaunchpadPage: false,
    showPointsBadge: false,
    showLeaderboardPage: true,
    showReferralsPage: true,
    showRyoPages: false,
    showInfoPage: false,
    showNitroPage: false,
    showBoosterPage: false,
    showRewardsPage: false,
    showPartnersPage: false,
    showStake: false,
    showSpNft: false,
    showAnnouncement: false,
    showAirdropClaim: false,
    showBadges: process.env.NEXT_PUBLIC_SHOW_BADGES
      ? JSON.parse(process.env.NEXT_PUBLIC_SHOW_BADGES ?? '')
      : [],
    showVaults: true,
    showRouterIntentPage: true,
    showDosirakPage: false
  },
  URLS: {
    subgraphUrl: '',
    middlewareUrl: 'https://api.scribe.exchange/api/v1',
    explorerApiUrl: '',
    subgraphUrlV3:
      'https://api.goldsky.com/api/public/project_clyssta42hwyl01um0r6ad90c/subgraphs/scribe-analytics/1.0.6/gn',
    subgraphUrlSteer: '',
    middlewareUrlV3: 'https://backend.scribe.exchange/api',
    merklApiUrl: '',
    steerApiUrl: '',
    subgraphUrlNitroPools: '',
    gammaApiUrl: 'https://wire2.gamma.xyz/scribe/scroll'
  }
};
